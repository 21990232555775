import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo"

const NewsPage = () => {
  const data = useStaticQuery(graphql`{
  banner: file(relativePath: {eq: "2xbanner6.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  default: file(relativePath: {eq: "default_works.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 720, quality: 80, layout: CONSTRAINED)
    }
  }
  topnews: allWpPost(
    filter: {categories: {nodes: {elemMatch: {slug: {eq: "top_news"}}}}}
    sort: {fields: date, order: DESC}
    limit: 2
  ) {
    edges {
      node {
        id
        slug
        title
        date(formatString: "YYYY-MM-DD")
        news {
          newsTitleCn
          newsCover {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 700, quality: 80, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
  news: allWpPost(
    filter: {categories: {nodes: {elemMatch: {slug: {eq: "news"}}}}}
    sort: {fields: date, order: DESC}
    limit: 1000
  ) {
    pageInfo {
      hasNextPage
    }
    edges {
      node {
        id
        slug
        title
        date(formatString: "YYYY-MM-DD")
        news {
          newsTitleCn
          newsCover {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 700, quality: 80, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
}
`)

  const posts = data.news.edges
  const postsPerPage = 6
  const arrayChunks = posts.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index/postsPerPage);
    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(item)
    return resultArray
  }, []);
  const [showNews, setShowNews] = useState(arrayChunks[0]);
  const [page, setPage] = useState(1);
  const [moreButton, setMoreButton] = useState(arrayChunks.length > 1);

  const renderTopNews = () => {
    const news = data.topnews.edges

    const result = news.map((item) => {
      const { id, slug, news, date } = item.node
      const { newsTitleCn } = news

      return (
        <div key={id} className="li wow fadeInUp">
          <Link to={`/news/${slug}/`} className="list02-item">
            <div className="pic">
              <div className="img">
                {news.newsCover ? (
                  <GatsbyImage image={news.newsCover?.localFile?.childImageSharp?.gatsbyImageData} alt="" />
                ) : (
                  <GatsbyImage image={data.default?.childImageSharp?.gatsbyImageData} alt="" />
                )}
              </div>
            </div>
            <div className="txt">
              <div className="data">{date}——</div>
              <div className="tit">{newsTitleCn.replace("<br />", "")}</div>
            </div>
          </Link>
        </div>
      );
    })

    return result
  }
  const renderLeftNews = () => {
    const news = showNews
    const result = news.map((item) => {
      const { id, slug, news, date } = item.node
      const { newsTitleCn } = news
      return (
        <div key={id} className="li wow fadeInUp">
          <Link to={`/news/${slug}/`} className="list02-item">
            <div className="pic">
              <div className="img">
                {news.newsCover ? (
                  <GatsbyImage image={news.newsCover?.localFile?.childImageSharp?.gatsbyImageData} alt="" />
                ) : (
                  <GatsbyImage image={data.default?.childImageSharp?.gatsbyImageData} alt="" />
                )}
              </div>
            </div>
            <div className="txt">
              <div className="data">{date}——</div>
              <div className="tit">{newsTitleCn.replace("<br />", "")}</div>
            </div>
          </Link>
        </div>
      );
    })

    return result
  }

  const handleLoadMoreNews = () => {
    let index = page;
    setPage(Number(page + 1));

    if(index < arrayChunks.length) {
      setMoreButton(false);
    } else {
      setMoreButton(true);
    }
    setShowNews([...showNews, ...arrayChunks[index]]);
  }

  return (
    <Layout>
      <SEO title="最新消息" />
      <div className={`mainContent`}>
        <Header activePage="news" color={{ bg: "transparent", text: "#fff" }} />

        <div className="banner" data-bg-color="transparent" data-color="#fff">
          <GatsbyImage image={data.banner?.childImageSharp?.gatsbyImageData} alt="" />
        </div>
        <div
          className="point-bar min-height js-viewport-animation"
          data-bg-color="transparent"
          data-color="#fff"
          style={{ backgroundColor: "#000000" }}
        ></div>
        <div
          className="row05 row05-cn  js-viewport-animation"
          style={{ backgroundColor: "#2e2e2e" }}
          data-bg-color="transparent"
          data-color="#fff"
        >
          <div className="wp1180">
            <h3 className="tit01 tal">
              <svg
                className="pic"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 340.68 49.1"
              >
                <g>
                  <g>
                    <path d="M28.56,34.68c0-9.08-27-3.23-27-20.5C1.51,4.54,10.46,0,19,0,28.63,0,37.3,4.27,37.64,14.93h-10c-.55-4.88-3.37-6.46-8.53-6.46-2.89,0-6.95,1.37-6.95,4.88,0,8.33,27,2.34,27,20.16,0,11-9.84,15.07-19.4,15.07C9.5,48.58.07,43.49,0,32.07H10.46c.27,5.57,4.68,8.12,9.77,8.12C23.46,40.19,28.56,38.88,28.56,34.68Z" />
                    <path d="M75.42,47.55l-4.13-5.16c-3.44,3.3-7.71,5.78-12.59,5.78-8.33,0-15.21-5.85-15.21-14.38,0-5.3,2.68-9.08,6.88-12,.83-.62,1.65-1.11,2.48-1.72-2.2-2.48-3.86-5.3-3.86-8.68C49,4.47,54,0,60.76,0c6.4,0,11.7,4.2,11.7,10.87,0,4.89-3.37,7.92-6.95,10.6l6.4,7.78q2.48-2.79,4.75-5.78l6,6.12A66.38,66.38,0,0,1,77.41,36l9.36,11.56ZM58.28,26.63c-2.13,1.45-6.05,4.13-6.05,7a6.37,6.37,0,0,0,6.12,6.12c2.75,0,5.51-2.27,7.37-4.05Zm-.82-15.69c0,2,1.58,3.37,2.68,4.75,1.52-1,3.86-2.48,3.86-4.61A3,3,0,0,0,60.69,8,3.08,3.08,0,0,0,57.46,10.94Z" />
                    <path d="M135.15,47.57H122.9l-9.7-32.83-9.77,32.83H91.18L82.1.77H93.45L99,33.05,108.11.77h10.12l9.15,32.28L132.88.77h11.36Z" />
                    <path d="M151.06,10.61a5.31,5.31,0,1,1,5.31-5.3A5.31,5.31,0,0,1,151.06,10.61Zm0-9.62a4.32,4.32,0,1,0,4.33,4.32A4.32,4.32,0,0,0,151.06,1Z" />
                    <path d="M152.71,8.32,151.14,5.7H150V8.32h-.82V2.15h2c1.32,0,2.26.45,2.26,1.74A1.69,1.69,0,0,1,152,5.62l1.65,2.7ZM150,5.07h1.08c1,0,1.57-.38,1.57-1.18s-.56-1.11-1.57-1.11H150Z" />
                    <path d="M201.69,47.83,169.32,4.51h-.19V47.83h-1.4V2.73h2L202.13,46h.19V2.73h1.4v45.1Z" />
                    <path d="M217.23,47.83V2.73h26.31V4H218.63V23.81h23.44v1.28H218.63V46.56h25.93v1.27Z" />
                    <path d="M293.67,47.83h-2.16L278.45,4.07h-.13L265.51,47.83h-2.16L249.84,2.73h1.47l13,43.83h.2L277.3,2.73h2.17l13,43.83h.2l13-43.83h1.47Z" />
                    <path d="M340.68,37a11.51,11.51,0,0,1-1.11,5.13,11.65,11.65,0,0,1-3,3.82,12.62,12.62,0,0,1-4.37,2.36,17.34,17.34,0,0,1-5.25.79,19.34,19.34,0,0,1-7.68-1.49,16.27,16.27,0,0,1-5.76-4l1.08-1a15.54,15.54,0,0,0,5.64,3.91,17.52,17.52,0,0,0,6.65,1.31,15,15,0,0,0,4.72-.73,12.78,12.78,0,0,0,3.95-2.1,9.79,9.79,0,0,0,2.7-3.38,10.33,10.33,0,0,0,1-4.62,8.31,8.31,0,0,0-1.08-4.39,10.73,10.73,0,0,0-2.8-3.06,17.31,17.31,0,0,0-3.92-2.14c-1.47-.57-2.94-1.09-4.43-1.56Q324.25,25,321.92,24a15.07,15.07,0,0,1-4-2.42,9.68,9.68,0,0,1-2.61-3.44,11.53,11.53,0,0,1-.93-4.87,10.92,10.92,0,0,1,1.12-5,10.79,10.79,0,0,1,3-3.7,13.94,13.94,0,0,1,4.42-2.29,17.57,17.57,0,0,1,5.32-.8,16.63,16.63,0,0,1,6.53,1.31,12.86,12.86,0,0,1,5.07,3.73l-1.09,1A12.07,12.07,0,0,0,334.22,4a14.54,14.54,0,0,0-6-1.24,15.82,15.82,0,0,0-4.49.64,12.54,12.54,0,0,0-4,1.94,10,10,0,0,0-2.86,3.28,9.56,9.56,0,0,0-1.08,4.65,9.39,9.39,0,0,0,1,4.43,9.78,9.78,0,0,0,2.55,3.09,14.54,14.54,0,0,0,3.6,2.1q2,.83,4.11,1.53,2.86.95,5.35,1.94a19.27,19.27,0,0,1,4.36,2.39,10,10,0,0,1,2.93,3.41A10.37,10.37,0,0,1,340.68,37Z" />
                  </g>
                </g>
              </svg>
            </h3>
            <div className="list02 fix slide01">{renderTopNews()}</div>
            <ul className="list02 fix col3 js_list_load">
              {renderLeftNews()}
            </ul>
            <button
              onClick={handleLoadMoreNews}
              style={{ cursor: "point" }}
              className={`more-btn s2${moreButton ? "" : " hide"}`}
            ></button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default NewsPage
